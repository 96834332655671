import React, { createContext, useContext, useState, useEffect } from 'react'
import * as db from 'firebase/firestore'

export const StoreItemsContext = createContext()

export const StoreItemsProvider = ({ children }) => {
  const [ivystores, setIvystores] = useState({})
  const [storeItems, setStoreItems] = useState({})
  const [loadedItems, setLoadedItems] = useState({})
  const [isLoaded, setIsLoaded] = useState(false)

  const loadIvystore = async (identifier, type = 'slug') => {
    // Check if already loaded
    const existingStore = Object.values(ivystores).find(
      store => store[type] === identifier
    )
    if (existingStore) return existingStore

    let storeData = null

    if (type === 'id') {
      const docRef = db.doc(db.getFirestore(), 'ivystores', identifier)
      const docSnap = await db.getDoc(docRef)

      if (docSnap.exists()) {
        storeData = {
          id: docSnap.id,
          ...docSnap.data()
        }
      } else {
        return null
      }
    } else {
      const query = db.query(
        db.collection(db.getFirestore(), 'ivystores'),
        db.where(type, '==', identifier)
      )

      const snapshot = await db.getDocs(query)

      if (!snapshot.empty) {
        const storeDoc = snapshot.docs[0]
        storeData = {
          id: storeDoc.id,
          ...storeDoc.data()
        }
      } else {
        return null
      }
    }

    // Store in state
    setIvystores(prev => ({
      ...prev,
      [storeData.id]: storeData
    }))

    return storeData
  }

  const reloadStoreItem = async (item) => {
    const itemDocRef = db.doc(db.getFirestore(), 'ivystore_items', item.id)
    const itemDocSnap = await db.getDoc(itemDocRef)

    if (itemDocSnap.exists()) {
      const itemData = { id: itemDocSnap.id, ...itemDocSnap.data() }

      // Обновляем loadedItems
      setLoadedItems(prev => ({ ...prev, [item.id]: itemData }))

      // Обновляем storeItems если они загружены для этого store
      setStoreItems(prev => {
        if (!prev[item.user_id]) return prev

        return {
          ...prev,
          [item.user_id]: prev[item.user_id].map(storeItem =>
            storeItem.id === item.id ? itemData : storeItem
          )
        }
      })

      return itemData
    }

    return null
  }

  const loadStoreItems = async (storeId) => {
    // Return if already loaded
    if (storeItems[storeId]) {
      return storeItems[storeId]
    }

    const query = db.query(
      db.collection(db.getFirestore(), 'ivystore_items'),
      db.where('user_id', '==', storeId),
      db.orderBy('created_at', 'desc')
    )

    const snapshot = await db.getDocs(query)
    const items = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }))

    // Update both store items and loaded items
    setStoreItems(prev => ({
      ...prev,
      [storeId]: items
    }))

    // Update individual items cache
    const newLoadedItems = items.reduce((acc, item) => ({
      ...acc,
      [item.id]: item
    }), {})

    setLoadedItems(prev => ({
      ...prev,
      ...newLoadedItems
    }))

    return items
  }

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  const loadItem = async (id) => {
    if (loadedItems[id]) {
      return loadedItems[id]
    }

    const docRef = db.doc(db.getFirestore(), 'ivystore_items', id)
    const docSnap = await db.getDoc(docRef)

    if (docSnap.exists()) {
      const item = { id: docSnap.id, ...docSnap.data() }
      setLoadedItems(prev => ({ ...prev, [id]: item }))
      return item
    } else {
      console.error('No such document!')
      return null
    }
  }

  return (
    <StoreItemsContext.Provider value={{
      storeItems,
      ivystores: Object.values(ivystores),
      loadItem,
      loadStoreItems,
      isLoaded,
      loadIvystore,
      reloadStoreItem
    }}>
      {children}
    </StoreItemsContext.Provider>
  )
}

export const useIvystore = (storeId) => {
  const context = useContext(StoreItemsContext)
  if (!context) {
    throw new Error('useIvystore must be used within an StoreItemsProvider')
  }
  const [store, setStore] = useState(null)

  useEffect(() => {
    if (storeId) {
      context.loadIvystore(storeId, 'id')
        .then(setStore)
    }
  }, [storeId])

  return store
}

export const useIvystoreInfo = (slug) => {
  const context = useContext(StoreItemsContext)
  if (!context) {
    throw new Error('useIvystoreInfo must be used within an StoreItemsProvider')
  }
  const [store, setStore] = useState(undefined)

  useEffect(() => {
    if (slug) {
      context.loadIvystore(slug, 'slug')
        .then(setStore)
    } else {
      setStore(null)
    }
  }, [slug])

  return store
}

export const useIvystoreItems = () => {
  const context = useContext(StoreItemsContext)
  const [items, setItems] = useState([])

  useEffect(() => {
    if (storeId) {
      context.loadStoreItems(storeId).then(setItems)
    }
  }, [storeId])

  return items
}

export const useIvystoreItem = (id) => {
  const context = useContext(StoreItemsContext)
  const [item, setItem] = useState(null)

  useEffect(() => {
    if (id) {
      context.loadItem(id).then(setItem)
    }
  }, [id])

  return item
}

export const useLoadIvystoreItem = (id) => {
  const context = useContext(StoreItemsContext)
  if (!context) {
    throw new Error('useLoadIvystoreItem must be used within an StoreItemsProvider')
  }
  return context.loadItem(id)
}
